import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Loader from '../../components/Loader/Loader';
import { useGetProjectByIdQuery } from '../../store/slices/project/apis/authProjectApi';
import FlowView from './FlowView';

import type { ReactElement } from 'react';

function Flow(): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();

  const id = location.pathname.split('/').pop();

  const { data, isLoading } = useGetProjectByIdQuery(id!);

  if (isLoading) {
    return <Loader />;
  }

  const nodes = (data?.data.attributes.data as { nodes: [] })?.nodes;
  const edges = (data?.data.attributes.data as { edges: [] })?.edges;
  const name = data?.data.attributes.name || t('projects.newProject');

  return (
    <FlowView
      id={id!}
      name={name}
      projectNodes={nodes}
      projectEdges={edges}
    />
  );
}

export default Flow;
