import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
} from 'reactflow';

import { TRANSPORT_METHODS } from '../../../../constants/reactflow';

import type { EdgeProps } from 'reactflow';
import type { ITransportMethod } from '../../../../constants/reactflow';

function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const transportIcons = () => {
    const uniqueIcons: Array<ITransportMethod> = [];

    data?.methods.forEach((method: ITransportMethod) => {
      if (!uniqueIcons.some((item) => item.type === method.type)) {
        uniqueIcons.push(method);
      }
    });

    return uniqueIcons;
  };

  transportIcons();

  const getIcon = (value: string) => {
    const Icon = TRANSPORT_METHODS.find((item) => item.value === value)?.icon || null;

    return Icon ? <Icon width={8} height={8} /> : null;
  };

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{
          ...style,
          position: 'relative',
        }}
      />
      <EdgeLabelRenderer>
        <div
          style={{ transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)` }}
          className="nodrag nopan absolute flex-wrap bg-card max-w-6 items-center justify-center
                  z-[10000] shadow-light gap-[2px] p-[2px] flex"
        >
          {transportIcons()
            .map((method: ITransportMethod) => <div>{getIcon(method.value)}</div>)}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default CustomEdge;
