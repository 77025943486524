import {
  Plane, Ship, TramFront, Truck,
} from 'lucide-react';

import type { LucideIcon } from 'lucide-react';
import type { Node } from 'reactflow';

export const snapGrid = [5, 20];
export const SWIMLANE_NODES_LIMIT = 5;

export const SUBGROUP_Y = 65;

export const REACTFLOW_SIZES = {
  NODE_WIDTH: 140,
  NODE_HEIGHT: 95,
  GROUP_PADDING: 20,
  GROUP_WIDTH: 500,
  SUBGROUP_WIDTH: 200,
  SUBGROUP_H_PADDING: 10,
  SUBGROUP_V_PADDING: 10,
  UNIT_HEIGHT: 90,
  BLOCK_HEIGHT: 90,
  UNIT_BLOCK_H_PADDING: 5,
  UNIT_BLOCK_V_PADDING: 5,
};

export const NODE_TYPES = {
  GROUP: 'group',
  SUBGROUP: 'subgroup',
  SWIMLANE: 'swimlane',
  UNIT: 'unit',
  BLOCK: 'block',
  PRODUCT: 'product',
};

export const EDGE_TYPES = {
  CUSTOM: 'custom',
};

export type TNodeTypes = typeof NODE_TYPES[keyof typeof NODE_TYPES];

export const rfStyle = {
  backgroundColor: '#F3F4F4',
};

export const SwimlaneBorder = '1px dashed #DBDDDE';

const groupConfig = {
  draggable: false,
  selectable: false,
  zIndex: 1,
  style: {
    width: REACTFLOW_SIZES.GROUP_WIDTH,
    height: '80vh',
    minHeight: '550px',
    border: 'none',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '24px',
    paddingTop: 20,
    backgroud: 'rgba(0, 0, 0, 0.1)',
  },
};

export const subgroup = (label = '', height?: string | number) => ({
  type: NODE_TYPES.SUBGROUP,
  draggable: false,
  focusable: true,
  selectable: true,
  data: { label },
  zIndex: 1000,
  style: {
    zIndex: 10,
    width: REACTFLOW_SIZES.NODE_WIDTH,
    height: height ? `${height}px` : '70vh',
    minHeight: '450px',
    border: SwimlaneBorder,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    backgroud: 'rgba(0, 0, 0, 0.1)',
  },
});

/*
@Initial data for ReactFlow
*/

export const initialNodes: Node[] = [
  {
    id: 'group-1',
    data: { label: 'Upstream', type: NODE_TYPES.GROUP, id: 'group-1' },
    position: { x: -REACTFLOW_SIZES.GROUP_WIDTH, y: 0 },
    connectable: false,
    ...groupConfig,
  },
  {
    id: 'group-2',
    data: { label: 'Own Operations', type: NODE_TYPES.GROUP, id: 'group-2' },
    position: { x: REACTFLOW_SIZES.GROUP_PADDING, y: 0 },
    ...groupConfig,
  },
  {
    id: 'group-3',
    data: { label: 'Downstream', type: NODE_TYPES.GROUP, id: 'group-3' },
    position: { x: REACTFLOW_SIZES.GROUP_WIDTH + REACTFLOW_SIZES.GROUP_PADDING * 2, y: 0 },
    ...groupConfig,
  },
  // Default subgroups
  {
    id: 'subgroup-1',
    position: { x: REACTFLOW_SIZES.GROUP_PADDING, y: SUBGROUP_Y },
    ...subgroup('Tier 2'),
    parentNode: 'group-1',
  },
  {
    id: 'subgroup-2',
    position: { x: REACTFLOW_SIZES.NODE_WIDTH + REACTFLOW_SIZES.GROUP_PADDING * 2, y: SUBGROUP_Y },
    ...subgroup('Tier 1'),
    parentNode: 'group-1',
  },
  {
    id: 'subgroup-3',
    position: { x: REACTFLOW_SIZES.NODE_WIDTH * 2 + REACTFLOW_SIZES.GROUP_PADDING * 3, y: SUBGROUP_Y },
    ...subgroup('Supplier'),
    parentNode: 'group-1',
  },
  {
    id: 'subgroup-4',
    position: { x: REACTFLOW_SIZES.GROUP_PADDING, y: SUBGROUP_Y },
    ...subgroup('Value Add'),
    parentNode: 'group-2',
  },
  {
    id: 'subgroup-5',
    position: { x: REACTFLOW_SIZES.NODE_WIDTH + REACTFLOW_SIZES.GROUP_PADDING * 2, y: SUBGROUP_Y },
    ...subgroup('Product'),
    parentNode: 'group-2',
  },
  {
    id: 'subgroup-6',
    position: { x: REACTFLOW_SIZES.NODE_WIDTH * 2 + REACTFLOW_SIZES.GROUP_PADDING * 3, y: SUBGROUP_Y },
    ...subgroup('Downstream internal'),
    parentNode: 'group-2',
  },
  {
    id: 'subgroup-7',
    position: { x: REACTFLOW_SIZES.GROUP_PADDING, y: SUBGROUP_Y },
    ...subgroup('Logistic and Distribution'),
    parentNode: 'group-3',
  },
  {
    id: 'subgroup-8',
    position: { x: REACTFLOW_SIZES.NODE_WIDTH + REACTFLOW_SIZES.GROUP_PADDING * 2, y: SUBGROUP_Y },
    ...subgroup('End User'),
    parentNode: 'group-3',
  },
  {
    id: 'subgroup-9',
    position: { x: REACTFLOW_SIZES.NODE_WIDTH * 2 + REACTFLOW_SIZES.GROUP_PADDING * 3, y: SUBGROUP_Y },
    ...subgroup('End of Life'),
    parentNode: 'group-3',
  },
];

// Data to create a new unit node (Businness Unit Modal)
export const SCORE = [
  { value: 1, label: 'A' },
  { value: 2, label: 'B' },
  { value: 3, label: 'C' },
  { value: 4, label: 'D' },
  { value: 5, label: 'E' },
  { value: 6, label: 'F' },
];

export type TScoreValue = typeof SCORE[number]['value'];

export const CURRENCIES = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
];

export const DEFAULT_CURRENCY = 'USD';

export interface ITransportMethod {
  value: string,
  label: string,
  icon: LucideIcon,
  type: 'road' | 'air' | 'water' | 'rail',
}

export const TRANSPORT_METHODS: Array<ITransportMethod> = [
  {
    value: 'van', label: 'Van', icon: Truck, type: 'road',
  },
  {
    value: 'air', label: 'Air', icon: Plane, type: 'air',
  },
  {
    value: 'truck', label: 'Truck', icon: Truck, type: 'road',
  },
  {
    value: 'sea', label: 'Sea', icon: Ship, type: 'water',
  },
  {
    value: 'rigidtruck', label: 'Rigid truck', icon: Truck, type: 'road',
  },
  {
    value: 'rail', label: 'Rail', icon: TramFront, type: 'rail',
  },
  {
    value: 'arctictruck', label: 'Arctic truck', icon: Truck, type: 'road',
  },
];

// Data to create a new unit node (Businness Unit Modal)
export const UNIT_KEYS = [
  'name',
  'sector',
  'score',
  'financialPerformance',
  'financialPercent',
  'spend',
  'spendCurrency',
  'income',
  'incomeCurrency',
  'stakeholders',
  'description',
];

export const BLOCK_KEYS = [
  'name',
  'country',
  'city',
  'sector',
  'spend',
  'spendCurrency',
  'description',
];

export const PRODUCT_KEYS = [
  'name',
  'spend',
  'spendCurrency',
  'revenue',
  'revenueCurrency',
  'profit',
  'profitCurrency',
  'description',
];
