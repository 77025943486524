import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import ButtonsBlock from '../../../../components/ButtonsBlock/ButtonsBlock';
import {
  DialogContent, DialogHeader, DialogTitle,
} from '../../../../components/ui/dialog';
import { Form } from '../../../../components/ui/form';
import { siteBlockSchema } from '../../../../utils/validators';
import InputAndSelectBlock from './components/InputAndSelectBlock';
import InputWithLabel from './components/InputWithLabel';
import TextAreaWithLabel from './components/TextAreaWithLabel';

import type { ISiteBlockModal, ISiteBlockNode } from './interfaces/ISiteBlockModal';

function SiteBlockModal({ editableNode, createNode, onCloseModal }: ISiteBlockModal) {
  const { t } = useTranslation();

  const form = useForm<ISiteBlockNode>({
    resolver: yupResolver(siteBlockSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitted },
  } = form;

  const onSubmitHandler = handleSubmit((data: ISiteBlockNode) => {
    createNode(data, editableNode?.id);
    reset();
  });

  const onCloseModalHandler = () => {
    onCloseModal();
    reset();
  };

  useEffect(() => {
    reset({
      name: editableNode?.data.newNodeData?.name || '',
      country: editableNode?.data.newNodeData?.country || '',
      city: editableNode?.data.newNodeData?.city || '',
      sector: editableNode?.data.newNodeData?.sector || '',
      spend: editableNode?.data.newNodeData?.spend,
      spendCurrency: editableNode?.data.newNodeData?.spendCurrency || '',
      description: editableNode?.data.newNodeData?.description || '',
    });
  }, [reset, editableNode]);

  return (
    <Form {...form}>
      <DialogContent className="w-[454px]">
        <DialogHeader>
          <DialogTitle>{t('projects.siteBlock')}</DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmitHandler}>
          <InputWithLabel
            control={control}
            name="name"
            label={`${t('projects.name')}*`}
            placeholder={t('projects.siteBlockName')}
          />
          <InputWithLabel
            control={control}
            name="country"
            label={t('projects.country')}
            placeholder={t('projects.country')}
          />
          <InputWithLabel
            control={control}
            name="city"
            label={t('projects.city')}
            placeholder={t('projects.city')}
          />
          <InputWithLabel
            control={control}
            name="sector"
            label={t('projects.sector')}
            placeholder={t('projects.sector')}
          />

          <InputAndSelectBlock control={control} inputName="spend" selectName="spendCurrency" />

          <TextAreaWithLabel
            control={control}
            name="description"
            label={t('projects.description')}
            placeholder={t('projects.typeYourMsg')}
          />

          <ButtonsBlock
            onCancel={onCloseModalHandler}
            disabled={!isValid && isSubmitted}
          />

        </form>
      </DialogContent>
    </Form>
  );
}

export default SiteBlockModal;
