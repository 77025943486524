import { Label } from '@radix-ui/react-label';

import { Checkbox } from '../../../../../components/ui/checkbox';
import { TRANSPORT_METHODS } from '../../../../../constants/reactflow';
import cn from '../../../../../utils/helpers';

import type { ICheckboxBlock } from './interfaces/ICheckboxBlock';

function CheckboxBlock({ methods, addMethods, label }: ICheckboxBlock) {
  return (
    <div>
      <Label className="text-sm font-medium">
        {label}
      </Label>
      <div className="flex flex-wrap">
        {TRANSPORT_METHODS.map((method) => {
          const isActive = methods.some((item) => item.value === method.value);

          return (
            <div className="flex w-[50%] items-center space-x-2 mt-4">
              <Checkbox
                id={method.value}
                checked={isActive}
                className={cn(`flex justify-center items-center border-[2px] border-gray3
                bg-card rounded-md w-[22px] h-[22px] cursor-pointer`, {
                  'border-none': isActive,
                  'text-card': isActive,
                  'bg-primary': isActive,
                })}
                onClick={() => addMethods(method)}
              />
              <label
                htmlFor={method.value}
                className="flex items-center text-center text-sm font-medium leading-none cursor-pointer"
              >
                {method.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CheckboxBlock;
