import {
  NODE_TYPES,
  REACTFLOW_SIZES,
  subgroup,
  SUBGROUP_Y,
  SWIMLANE_NODES_LIMIT,
  SwimlaneBorder,
} from '../constants/reactflow';
import {
  calculateSubgroupX,
  calculateUnitNodeHeight,
  getCountOfNodeChildren,
  resetOnDragOverStyles,
} from './reactflow';

import type { DragEvent, SetStateAction } from 'react';
import type { Node } from 'reactflow';
import type { TNodeTypes } from '../constants/reactflow';
import type { IBusinessUnitNode } from '../pages/Flow/components/Modals/interfaces/IBusinessUnitModal';
import type { IProductNode } from '../pages/Flow/components/Modals/interfaces/IProductModal';
import type { ISiteBlockNode } from '../pages/Flow/components/Modals/interfaces/ISiteBlockModal';

const {
  NODE_WIDTH,
  NODE_HEIGHT,
  BLOCK_HEIGHT,
  GROUP_PADDING,
  UNIT_BLOCK_V_PADDING,
  UNIT_BLOCK_H_PADDING,
  SUBGROUP_H_PADDING,
  SUBGROUP_V_PADDING,
} = REACTFLOW_SIZES;

/*
  Drag Over handlers
*/

// Swimlane drag over handler: set opacity to 0.6 when the node is dragged over the target group node
export const onDragOverSwimlane = (
  event: DragEvent,
  nodes: Node[],
  setNodesCallback: (arg0: SetStateAction<Node[]>) => void,
  setTargetCallback: (arg0: Node | undefined) => void,
) => {
  event.dataTransfer.dropEffect = 'move';

  const { id } = (event.target as HTMLElement).dataset;

  if (id && id.includes(NODE_TYPES.SUBGROUP)) {
    const parentNodeId = nodes.find((n: Node) => n.id === id)?.parentNode;

    setNodesCallback((nodesData) => nodesData.map((n) => {
      if (n.type === NODE_TYPES.SUBGROUP) return n;

      if (n?.id === parentNodeId) {
        // if the node is dragged over the target node, set opacity to 0.6
        return { ...n, style: { ...n.style, opacity: 0.6, border: '1px solid #cecece' } };
      }

      return { ...n, opacity: 1, border: 'none' };
    }));
  } else {
    setNodesCallback((nodesData) => resetOnDragOverStyles(nodesData));
  }

  setTargetCallback(nodes.find((n) => n.id === id));
};

// Unit drag over handler: set opacity to 0.6 when the node is dragged over the target subgroup node
export const onDragOverBlock = (
  event: DragEvent,
  nodes: Node[],
  setNodesCallback: (arg0: SetStateAction<Node[]>) => void,
  setTargetCallback: (arg0: Node | undefined) => void,
) => {
  event.dataTransfer.dropEffect = 'move';

  const { id } = (event.target as HTMLElement).dataset;

  const parentDOMNode = (event.target as HTMLElement)?.parentNode as HTMLElement;
  const parentDOMNodeId = parentDOMNode?.id;

  setNodesCallback((nodesData: Node[]) => nodesData.map((n) => {
    if (n.type === NODE_TYPES.SUBGROUP) {
      if (n.id === id) {
        return { ...n, style: { ...n.style, opacity: 0.6, border: '1px solid #cecece' } };
      }
      return { ...n, style: { ...n.style, opacity: 1, border: SwimlaneBorder } };
    }
    if (n.type === NODE_TYPES.UNIT || parentDOMNodeId.includes(NODE_TYPES.UNIT)) {
      if (n.id === id || parentDOMNodeId === n.id) {
        return { ...n, style: { ...n.style, opacity: 0.6, border: '1px solid #cecece' } };
      }
      return { ...n, style: { ...n.style, opacity: 1, border: 'none' } };
    }

    return n;
  }));

  setTargetCallback(nodes.find((n: Node) => n.id === id || parentDOMNodeId === n.id));
};

/*
  Drop handlers
*/

// Drop the swimlane node to add new subgroup
export const onSwimlaneDrop = (
  nodes: Node[],
  setNodesCallback: (arg0: SetStateAction<Node[]>) => void,
  setTargetCallback: (arg0: Node | undefined) => void,
  target: Node | null | undefined,
) => {
  if (target && target.parentNode) {
    const nodeChildCount = getCountOfNodeChildren(nodes, target.parentNode);

    // if the count of children is equal to the limit, do not create a new subgroup
    if (nodeChildCount === SWIMLANE_NODES_LIMIT) {
      setNodesCallback((nodesData) => resetOnDragOverStyles(nodesData));
      setTargetCallback(undefined);

      return;
    }

    const height = target.height || target.style?.height;

    // create a new subgroup node
    const nodeChild = {
      ...subgroup('', String(height)),
      id: `${NODE_TYPES.SUBGROUP}-${new Date().getTime().toString()}`,
      position: { x: calculateSubgroupX(nodeChildCount, NODE_WIDTH), y: SUBGROUP_Y },
      parentNode: target.parentNode,
    };

    // increase Group node width
    const parentNodeIndex = nodes.findIndex((n) => n.id === target.parentNode);

    setNodesCallback((nodesData) => nodesData.map((n) => {
      if (n.id === target.parentNode) {
        const width = NODE_WIDTH + GROUP_PADDING;

        return {
          ...n,
          style: { ...n.style, width: Number(n.style?.width || NODE_WIDTH) + width },
          width: (n.width || NODE_WIDTH) + width,
        };
      } if (
        (parentNodeIndex === 0 && n.id === 'group-2')
        || (parentNodeIndex === 0 && n.id === 'group-3')
        || (parentNodeIndex === 1 && n.id === 'group-3')
      ) {
        return {
          ...n,
          position: { ...n.position, x: n.position.x + NODE_WIDTH + GROUP_PADDING },
        };
      }

      return n;
    }).concat(nodeChild));
  }

  setNodesCallback((nodesData) => resetOnDragOverStyles(nodesData));

  setTargetCallback(undefined);
};

// Drop unit/block/product node to add new unit node to the subgroup
export const onBlockDrop = (
  nodes: Node[],
  setNodesCallback: (arg0: SetStateAction<Node[]>) => void,
  setTargetCallback: (arg0: Node | undefined) => void,
  target: Node | null | undefined,
  type: string,
  newNodeData: IBusinessUnitNode | IProductNode | ISiteBlockNode,
) => {
  const isUnit = target?.type === NODE_TYPES.UNIT;

  const parentNode = target?.id;
  let nodeWidth = NODE_WIDTH - 30;
  let divsCount = 0;
  let positionX;
  let positionY;

  if (target?.id.includes(NODE_TYPES.BLOCK) || target?.id.includes(NODE_TYPES.PRODUCT)) return;

  if (target && target.parentNode) {
    const subgroupChildCount = getCountOfNodeChildren(nodes, target.id);

    // get all children of the subgroup
    const childrenHeight = nodes
      .filter((n) => n.parentNode === target.id)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    if (target.id.includes(NODE_TYPES.UNIT)) {
      const unitChildren = nodes.filter((n) => n.parentNode === target.id);
      const lastChild = unitChildren[unitChildren.length - 1];
      // get the count of children of the target node to calculate the Y position for the new node
      divsCount = document.getElementById(target.id)?.childNodes.length || 0;

      positionX = UNIT_BLOCK_H_PADDING;
      // if unit has children, calculate the Y position based on the last child position
      positionY = unitChildren.length > 0
        ? lastChild.position.y + BLOCK_HEIGHT + (UNIT_BLOCK_V_PADDING * 2)
        : divsCount * 12;
    } else {
      nodeWidth = NODE_WIDTH - (SUBGROUP_H_PADDING * 2);
      positionX = SUBGROUP_H_PADDING;
      positionY = childrenHeight
        ? childrenHeight + SUBGROUP_Y + (SUBGROUP_V_PADDING * subgroupChildCount)
        : SUBGROUP_Y;
    }

    // create a new unit node
    const id = `${type}-${new Date().getTime().toString()}`;
    const nodeChild = {
      id,
      position: { x: positionX, y: positionY },
      data: {
        id, parentNode, width: nodeWidth, newNodeData,
      },
      style: { width: nodeWidth },
      type,
      parentNode,
    };

    const groupNodeId = isUnit ? nodes.find((n) => n.id === target.parentNode)?.parentNode : undefined;
    const subgroupHeight = nodes.find((n) => n.id === target.parentNode)?.height;

    const updatedNodes = nodes.map((n) => {
      if (isUnit && n.id === target.id) {
        const height = calculateUnitNodeHeight(nodes, n, divsCount);

        return {
          ...n, height, style: { ...n.style, height }, data: { ...n.data, height },
        };
      }
      if ((n.parentNode === target.parentNode
          || n.id === target.parentNode
          || n.id === groupNodeId
          || (n.parentNode && n.parentNode === groupNodeId))
        && (subgroupHeight && (subgroupHeight - childrenHeight) < NODE_HEIGHT * 3.5)
      ) {
        return {
          ...n,
          height: n.height! + BLOCK_HEIGHT + (n.id.includes('subgroup') ? SUBGROUP_V_PADDING : GROUP_PADDING),
          style: {
            ...n.style,
            height: n.height! + BLOCK_HEIGHT + (n.id.includes('subgroup') ? SUBGROUP_V_PADDING : GROUP_PADDING),
          },
        };
      }
      return n;
    }).concat(nodeChild);

    setNodesCallback(updatedNodes);
  }

  setNodesCallback((nodesData) => resetOnDragOverStyles(nodesData));

  setTargetCallback(undefined);
};

// Process the drag and drop events based on the type of the dragged node
export const processByDragType = (
  event: DragEvent,
  nodes: Node[],
  setNodesCallback: (arg0: SetStateAction<Node[]>) => void,
  setTargetCallback: (arg0: Node | undefined) => void,
  draggedNodeType: TNodeTypes,
) => {
  event.preventDefault();

  const type = event.dataTransfer.getData('application/reactflow') || draggedNodeType;

  switch (type) {
    case NODE_TYPES.SWIMLANE:
      onDragOverSwimlane(event, nodes, setNodesCallback, setTargetCallback);
      break;
    case NODE_TYPES.UNIT:
    case NODE_TYPES.BLOCK:
    case NODE_TYPES.PRODUCT:
      onDragOverBlock(event, nodes, setNodesCallback, setTargetCallback);
      break;
    default:
  }
};
