import { useTranslation } from 'react-i18next';

import { DEFAULT_CURRENCY } from '../../../../constants/reactflow';
import cn, { formatDistance } from '../../../../utils/helpers';
import SourceAndTarget from '../CustomHandle/SourceAndTarget';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import InfoBlock from '../Unit/components/InfoBlock';

import type { ISiteBlock } from './interfaces/ISiteBlock';

function SiteBlock({ selected, data }: ISiteBlock) {
  const { t } = useTranslation();

  const {
    name,
    country,
    city,
    sector,
    spend,
    spendCurrency,
    description,
  } = data.newNodeData;

  const location = () => {
    if (!city && !country) return '';

    if (city && country) return `${city}, ${country}`;

    return city || country;
  };

  return (
    <TooltipWrapper description={description} contentWidth={data.width}>
      <div
        data-id={data.id}
        className={cn('bg-card min-h-[80px] flex flex-col p-1 border border-[#C9E1E5] shadow-md', {
          border: selected,
          'border-deepCyan': selected,
        })}
      >

        <SourceAndTarget id={data.id} />

        <div>
          <p className="font-medium text-[8px] line-clamp-2">{name}</p>
        </div>

        <div className="w-[100%] h-[1px] my-1 mx-auto bg-[#C9E1E5]" />

        <div className="flex justify-start">
          <InfoBlock title={t('projects.sector')} value={sector} />
        </div>

        <div className="flex justify-start pt-1">
          <InfoBlock title={t('projects.location')} value={location()} />
        </div>

        <div className="flex justify-start pt-1">
          {spend ? (
            <InfoBlock
              title={t('projects.spend')}
              value={formatDistance(`${spend}`)}
              currency={spendCurrency || DEFAULT_CURRENCY}
            />
          ) : null}
        </div>

      </div>
    </TooltipWrapper>
  );
}

export default SiteBlock;
