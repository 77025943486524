import { Link } from 'react-router-dom';
import {
  FilePlusIcon, FileTextIcon, GearIcon, UploadIcon,
} from '@radix-ui/react-icons';

import type { ReactElement } from 'react';
import type ISidebar from '../interfaces/ISidebar';

import logoSmall from '../../../assets/images/logoSmall.svg';

function SideBar({ onLogout }: ISidebar): ReactElement {
  return (
    <div className="h-full w-fit bg-card px-4 py-6 flex flex-col justify-between">
      <div>
        <Link to="/" className="flex justify-center mb-12"><img src={logoSmall} alt="logo" /></Link>
        <Link to="/" className="mb-4 block p-2 hover:shadow-lg rounded text-icon hover:text-secondary-foreground">
          <FilePlusIcon width={30} height={30} />
        </Link>
        <Link to="/" className="mb-4 block p-2 hover:shadow-lg rounded text-icon hover:text-secondary-foreground">
          <FileTextIcon width={30} height={30} />
        </Link>
        <div className="bg-gray w-full h-px mb-4" />
        <Link to="/" className="mb-4 block p-2 hover:shadow-lg rounded text-icon hover:text-secondary-foreground">
          <GearIcon width={30} height={30} />
        </Link>
      </div>
      <div
        className="block p-2 hover:shadow-lg rounded text-icon hover:text-secondary-foreground"
        role="presentation"
        onClick={onLogout}
      >
        <UploadIcon width={30} height={30} className="rotate-90" />
      </div>
    </div>
  );
}

export default SideBar;
