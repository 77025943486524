import {
  FormControl, FormField, FormItem, FormLabel, FormMessage,
} from '../../../../../components/ui/form';
import { Textarea } from '../../../../../components/ui/textarea';

import type { ITextAreaWithLabel } from './interfaces/ITextAreaWithLabel';

function TextAreaWithLabel({
  control, name, label, placeholder,
}: ITextAreaWithLabel) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormItem className="mb-4">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              rows={3}
              className="resize-none"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export default TextAreaWithLabel;
