import {
  Album, Box, Boxes, BoxSelect,
} from 'lucide-react';

import { NODE_TYPES } from '../../../../constants/reactflow';

import type { DragEvent } from 'react';
import type { ISidebar } from './interfaces/ISidebar';

const SIDEBAR_MENU_ITEMS = [
  {
    id: 0, label: 'Swimlane', type: NODE_TYPES.SWIMLANE, icon: <BoxSelect width={25} height={25} />,
  },
  {
    id: 1, label: 'Unit', type: NODE_TYPES.UNIT, icon: <Boxes width={25} height={25} />,
  },
  {
    id: 2, label: 'Site Block', type: NODE_TYPES.BLOCK, icon: <Box width={25} height={25} />,
  },
  {
    id: 3, label: 'Product', type: NODE_TYPES.PRODUCT, icon: <Album width={25} height={25} />,
  },
];

function Sidebar({ setDraggedNodeType }: ISidebar) {
  // nodeType is a string that represents the type of node being dragged
  const onDragStart = (event: DragEvent, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    setDraggedNodeType(nodeType);

    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div
      className="fixed flex-col justify-center items-center z-[1000]
        bg-card right-[25px] top-[249px] h-[368px] w-[92px] rounded-md shadow-lg"
    >
      {SIDEBAR_MENU_ITEMS.map((item) => (
        <div
          key={item.id}
          className="w-[92px] h-[76px] items-center justify-center mt-4 cursor-grab"
          aria-label={item.label}
          onDragStart={(event) => onDragStart(event, item.type)}
          draggable
        >
          <div className="flex justify-center">{item.icon}</div>
          <p className="font-medium text-sm text-eclipse text-center pt-2">{item.label}</p>
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
