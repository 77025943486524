import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

import { Button } from '../../../../components/ui/button';

import type { IHeader } from './interfaces/IHeader';

function Header({ projectName, isLoading, onClick }: IHeader) {
  const { t } = useTranslation();

  return (
    <header className="flex justify-between items-center px-10 h-[72px]">
      <div className="z-[2000] flex items-center">
        <Link to="/">
          <ArrowLeft />
        </Link>
        <h1 className="ml-3 text-xl font-semibold">{projectName}</h1>
      </div>

      <Button
        type="button"
        onClick={onClick}
        disabled={isLoading}
        className="z-[10000] cursor-pointer"
      >
        {t('projects.save')}
      </Button>
    </header>
  );
}

export default Header;
