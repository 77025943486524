import { useTranslation } from 'react-i18next';

import ButtonsBlock from '../../../../components/ButtonsBlock/ButtonsBlock';
import {
  Dialog, DialogContent,
  DialogHeader, DialogTitle,
} from '../../../../components/ui/dialog';

import type { ReactElement } from 'react';
import type { ISaveModal } from './interfaces/ISaveModal';

function SaveModal({ blocker, onSaveProject }: ISaveModal): ReactElement {
  const { t } = useTranslation();

  const onSaveHandler = () => {
    onSaveProject();
  };

  return (
    <Dialog open={blocker.state === 'blocked'} onOpenChange={blocker.reset}>
      <DialogContent className="w-[454px]">
        <DialogHeader>
          <DialogTitle className="text-2xl">{t('projects.whouldYouLikeToSaveProject')}</DialogTitle>
          <DialogTitle className="text-sm text-eclipse font-medium">
            {t('projects.otherwiseYouWillLoseChanges')}
          </DialogTitle>
        </DialogHeader>
        <ButtonsBlock
          cancelTitle={t('projects.closeWithoutSaving')}
          onCancel={blocker.proceed}
          onSubmit={onSaveHandler}
        />
      </DialogContent>
    </Dialog>
  );
}

export default SaveModal;
