import Theme from '../../constants/Theme';

export default {
  welcomeMessage: 'Try to build something great',
  unknownError: 'Unknown error',
  createdOn: 'Created on',
  createNew: 'Create new',
  edit: 'Edit',
  rename: 'Rename',
  delete: 'Delete',
  name: 'Name',
  theme: {
    [Theme.light]: 'Light',
    [Theme.dark]: 'Dark',
  },
  wentWrong: 'Something went wrong',
  tryAgain: 'Try again',
};
