import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useDeleteProjectMutation, usePutProjectMutation } from '../../../store/slices/project/apis/authProjectApi';
import DropDownMenu from './DropDownMenu';
import ProjectDeleteModal from './ProjectDeleteModal';
import ProjectFormModal from './ProjectFormModal';

import type { MouseEvent, ReactElement } from 'react';
import type IProjectItem from '@/src/pages/Projects/interfaces/IProjectItem';
import type TProjectForm from '../interfaces/TProjectForm';

import logoSmall from '../../../assets/images/logoSmall.svg';

function ProjectItem({ project }: IProjectItem): ReactElement {
  const { t } = useTranslation();

  const [isFormModalOpen, setIsFormModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [deleteProject, {
    isLoading: isDeleteLoading,
  }] = useDeleteProjectMutation();

  const [renameProject, {
    isLoading: isRenameLoading,
  }] = usePutProjectMutation();

  const openEditFormModal = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsFormModalOpen(true);
  };
  const openDeleteModal = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDeleteModalOpen(true);
  };

  const onSubmitHandler = (formData: TProjectForm) => {
    if (formData.id) {
      const bodyData = {
        data: {
          name: formData.name,
        },
      };
      renameProject({ id: `${formData.id}`, data: bodyData }).unwrap().then(() => {
        setIsFormModalOpen(false);
      });
    }
  };

  const onDeleteHandler = () => {
    deleteProject({ id: `${project.id}` }).unwrap().then(() => {
      setIsDeleteModalOpen(false);
    });
  };

  return (
    <>
      <Link
        to={`/flow/${project.id}`}
        className="rounded-md shadow-lg overflow-hidden bg-card"
      >
        <div key={project.id}>
          <div className="flex justify-center items-center py-12 bg-secondary relative">
            <DropDownMenu onRenameClick={openEditFormModal} onDeleteClick={openDeleteModal} />
            <img src={logoSmall} alt="ProjectLogo" width={64} height={64} />
          </div>
          <div className="p-4 bg-card flex flex-col">
            <span className="text-base font-semibold">{project.attributes.name}</span>
            <span className="text-sm text-gray2 font-medium mt-1">
              {`${t('common.createdOn')}
                 ${DateTime.fromJSDate(new Date(project.attributes.createdAt)).toFormat('MMMM d')}`}
            </span>
          </div>
        </div>
      </Link>
      {isFormModalOpen ? (
        <ProjectFormModal
          isOpen={isFormModalOpen}
          setIsOpen={setIsFormModalOpen}
          project={project}
          onSubmit={onSubmitHandler}
          isLoading={isRenameLoading}
        />
      ) : null}
      {isDeleteModalOpen ? (
        <ProjectDeleteModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          project={project}
          onDelete={onDeleteHandler}
          isLoading={isDeleteLoading}
        />
      ) : null}
    </>
  );
}

export default ProjectItem;
