import {
  FormControl, FormField, FormItem, FormLabel, FormMessage,
} from '../../../../../components/ui/form';
import { Input } from '../../../../../components/ui/input';

import type { IInputWithLabel } from './interfaces/IInputWithLabel';

function InputWithLabel({
  control, name, label, placeholder,
}: IInputWithLabel) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormItem className="mb-4">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              placeholder={placeholder}
              onChange={onChange}
              value={value}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export default InputWithLabel;
