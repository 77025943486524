import { useTranslation } from 'react-i18next';

import {
  Tooltip, TooltipContent, TooltipProvider,
} from '../../../../components/ui/tooltip';
import { formatDistance } from '../../../../utils/helpers';

import type { ITransportMethod } from '../../../../constants/reactflow';
import type { IEdgeTooltip } from './interfaces/IEdgeTooltip';

function EdgeTooltip({ positionX, positionY, data }: IEdgeTooltip) {
  const { t } = useTranslation();

  if (!data.distance && !data.methods?.length) return null;

  return (
    <TooltipProvider>
      <Tooltip open>
        <TooltipContent
          side="bottom"
          className="flex flex-col z-[10000] min-w-[200px] border-[1px] border-[#C9E1E5] shadow-tooltip p-2"
          style={{ transform: `translate(${positionX}px,${(positionY + 50)}px)` }}
        >
          {data?.methods && data.methods.length > 0 ? (
            <>
              <p className="flex flex-1 text-eclipse font-normal uppercase text-[10px]">
                {t('projects.transportMethod')}
                :
              </p>
              <div className="flex flex-row flex-wrap">
                {data.methods?.map((method: ITransportMethod, idx: number) => (
                  <div className="py-1 pr-1">
                    <span className="text-sm font-medium">
                      {method.label}
                      {idx !== (data.methods?.length ?? 0) - 1 ? ',' : ''}
                    </span>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {data?.distance ? (
            <>
              <p className="flex flex-1 text-eclipse font-normal uppercase text-[10px]">
                {t('projects.distance')}
                :
              </p>
              <p className="text-sm font-medium text-darkText">
                {formatDistance(data?.distance)}
                {' '}
                {t('projects.km')}
              </p>
            </>
          ) : null}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default EdgeTooltip;
