import { useTranslation } from 'react-i18next';

import {
  Arrow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../components/ui/tooltip';
import TextBlock from './TextBlock';

import type { ITooltipWrapper } from './interfaces/ITooltipWrapper';

function TooltipWrapper({
  contentWidth,
  stakeholders,
  description,
  children,
}: ITooltipWrapper) {
  const { t } = useTranslation();

  if (!description && !stakeholders) return children;

  return (
    <TooltipProvider>
      <Tooltip disableHoverableContent>
        <TooltipTrigger asChild>
          {children}
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          className={`w-[${contentWidth}px] z-[10000] min-h-[20px] border-[1px] border-[#F3F4F4]
          flex flex-col gap-1`}
        >
          <Arrow className="fill-[#F3F4F4]" width={4} height={4} />
          <TextBlock title={t('projects.majorStakeholders')} description={stakeholders} />
          <TextBlock title={t('projects.description')} description={description} />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default TooltipWrapper;
