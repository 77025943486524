import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import paramsSerializer from '../../utils/paramsSerializer';
import PROJECTS from '../tags/project';

import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import type { RootState } from '../types/TStore';

const authQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    // https://redux-toolkit.js.org/rtk-query/usage-with-typescript#typing-dispatch-and-getstate
    const {
      auth: { jwt },
    } = getState() as RootState;

    if (jwt) {
      headers.set('Authorization', `Bearer ${jwt}`);
    }

    return headers;
  },
  paramsSerializer,
});

const authQueryWithReauth: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await authQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    return result;
  }

  return result;
};

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: authQueryWithReauth,
  tagTypes: [PROJECTS],
  endpoints: () => ({}),
});

export default authApi;
