const paramsSerializer = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // Serialize arrays with the '[]' notation
      value.forEach((item) => {
        searchParams.append(`${key}[]`, item);
      });
    } else {
      // Serialize other values
      searchParams.append(key, value);
    }
  });

  return searchParams.toString();
};

export default paramsSerializer;
