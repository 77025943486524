import { Navigate } from 'react-router-dom';

import { useAppSelector } from '../../store/hooks/useApp';
import selectJtwSelector from '../../store/slices/auth/selectors';
import Page from './Page';

import type { ReactElement } from 'react';
import type IPageProtected from './interfaces/IPageProtected';

/**
 * Protected page layout
 */
function PageProtected({ redirectUri }: IPageProtected): ReactElement {
  const jwt = useAppSelector(selectJtwSelector);
  return jwt ? (
    <Page />
  ) : (
    <Navigate to={redirectUri} />
  );
}

export default PageProtected;
