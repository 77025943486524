import type { ITextBlock } from './interfaces/ITextBlock';

function TextBlock({ title, description }: ITextBlock) {
  if (!description) return null;

  return (
    <div>
      <p className="uppercase font-normal text-[4px] leading-[8px] text-eclipse">
        {title}
        :
      </p>
      <p className="font-normal text-[6px] leading-[8px] text-[#0D1314]">{description}</p>
    </div>
  );
}

export default TextBlock;
