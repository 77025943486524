import { useTranslation } from 'react-i18next';

import { DEFAULT_CURRENCY, REACTFLOW_SIZES } from '../../../../constants/reactflow';
import cn, { formatDistance } from '../../../../utils/helpers';
import { getScoreValue } from '../../../../utils/reactflow';
import SourceAndTarget from '../CustomHandle/SourceAndTarget';
import TooltipWrapper from '../TooltipWrapper/TooltipWrapper';
import InfoBlock from './components/InfoBlock';
import { InfoBlockType } from './components/interfaces/IInfoBlock';

import type { IUnit } from './interface/Unit.interfaces';

export default function Unit({ selected, data }: IUnit) {
  const { t } = useTranslation();

  const {
    name,
    sector,
    score,
    financialPerformance,
    financialPercent,
    spend,
    spendCurrency,
    income,
    incomeCurrency,
    stakeholders,
    description,
  } = data.newNodeData;

  const { height, id } = data;
  const { NODE_HEIGHT } = REACTFLOW_SIZES;

  return (
    <TooltipWrapper description={description} stakeholders={stakeholders} contentWidth={data.width}>
      <div
        id={id}
        data-id={id}
        className={cn('z-[1000] bg-aijiro min-h-[95px] flex flex-col p-1', {
          border: selected,
          'border-deepCyan': selected,
        })}
        style={{
          height: `${height || NODE_HEIGHT}px`,
        }}
      >

        <SourceAndTarget id={id} />

        <div id={id}>
          <p className="font-medium text-[8px] line-clamp-2">{name}</p>
        </div>

        <div className="w-[100%] h-[1px] my-1 mx-auto bg-[#C9E1E5]" />

        <div id={id} className="flex justify-start">
          {sector ? <InfoBlock id={id} title={t('projects.sector')} value={sector} /> : null}
        </div>

        <div className="flex justify-start pt-1">
          {score
            ? (
              <InfoBlock
                id={id}
                title={t('projects.score')}
                type={InfoBlockType.Score}
                value={getScoreValue(score)}
              />
            )
            : null}
          {(financialPerformance || financialPercent) ? (
            <InfoBlock
              id={id}
              title={t('projects.financial')}
              type={financialPerformance === 1 ? InfoBlockType.FinancialDown : InfoBlockType.FinancialUp}
              value={`${financialPercent}`}
            />
          ) : null}
        </div>

        <div className="flex justify-start pt-1">
          {spend
            ? (
              <div className="flex-1">
                <InfoBlock
                  id={id}
                  title={t('projects.spend')}
                  value={formatDistance(`${spend}`)}
                  currency={spendCurrency || DEFAULT_CURRENCY}
                />
              </div>
            )
            : null}
          {income ? (
            <div id={id} className="flex-1">
              <InfoBlock
                id={id}
                value={formatDistance(`${income}`)}
                title={t('projects.income')}
                currency={incomeCurrency || DEFAULT_CURRENCY}
              />
            </div>
          ) : null}
        </div>
      </div>
    </TooltipWrapper>
  );
}
