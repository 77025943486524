import { createSlice } from '@reduxjs/toolkit';

import authBaseApi from './apis/authApi';

import type IAuthStore from './interfaces/IAuthStore';

const initialState: IAuthStore = {
  jwt: undefined,
  user: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder): void => {
    builder.addMatcher(
      authBaseApi.endpoints.login.matchFulfilled,
      (state, { payload }): void => {
        state.jwt = payload.jwt;
        state.user = payload.user;
      },
    );
  },
});

export const {
  logout,
} = userSlice.actions;

export default userSlice.reducer;
