import { useTranslation } from 'react-i18next';

import {
  FormControl, FormField, FormItem, FormLabel, FormMessage,
} from '../../../../../components/ui/form';
import { Input } from '../../../../../components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '../../../../../components/ui/select';
import { CURRENCIES, DEFAULT_CURRENCY } from '../../../../../constants/reactflow';

import type { IInputAndSelectBlock } from './interfaces/IInputAndSelectBlock';

function InputAndSelectBlock({ control, inputName, selectName }: IInputAndSelectBlock) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-end">
      <FormField
        control={control}
        name={inputName}
        render={({ field: { onChange, value } }) => (
          <FormItem className="mb-4 flex-1">
            <FormLabel>{t(`projects.${inputName}`)}</FormLabel>
            <FormControl>
              <Input
                type="number"
                placeholder={t('projects.enterSumm')}
                onChange={onChange}
                value={value}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="w-3" />
      <FormField
        control={control}
        name={selectName}
        render={({ field: { onChange, value } }) => (
          <FormItem className="mb-4">
            <FormControl>
              <Select value={value} defaultValue={DEFAULT_CURRENCY} onValueChange={onChange}>
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder={value || DEFAULT_CURRENCY} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>{value}</SelectLabel>
                    {CURRENCIES.map((currency) => (
                      <SelectItem
                        key={currency.value}
                        value={currency.value}
                      >
                        {currency.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}

export default InputAndSelectBlock;
