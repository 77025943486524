import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons';

import { type IInfoBlock, InfoBlockType } from './interfaces/IInfoBlock';

export default function InfoBlock({
  id, title, value, type, currency,
}: IInfoBlock) {
  if (!value) return null;

  const extraStyles = () => {
    if (type === InfoBlockType.Score) {
      return 'flex w-[10px] h-[10px] bg-[#C9E1E5] rounded-full justify-center items-center';
    }

    return '';
  };
  return (
    <div id={id} className="h-4 flex-1 flex-col justify-between">
      <p className="uppercase text-[4px] pb-[2px] text-eclipse font-normal">
        {title}
        :
      </p>
      <div id={id} className="flex justify-start items-center">
        {type === InfoBlockType.FinancialUp && <ArrowUpIcon color="#30B90E" width={8} height={8} />}
        {type === InfoBlockType.FinancialDown && <ArrowDownIcon color="#DC2626" width={8} height={8} />}
        <p className={`text-[6px] leading-[6px] font-normal line-clamp-1 ${extraStyles()}`}>
          {value}
          {' '}
          {currency}
        </p>
      </div>
    </div>
  );
}
