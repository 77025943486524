import { Handle } from 'reactflow';

import cn from '../../../../utils/helpers';

import type { ICustomHandle } from './interfaces/ICustomHandle';

function CustomHandle({ type, position, id }: ICustomHandle) {
  return (
    <Handle
      type={type}
      position={position}
      id={id}
      className={cn('w-1 h-1 rounded-full bg-card border-deepCyan', {
        'left-[-2px]': position === 'left',
        'right-[-2px]': position === 'right',
      })}
    />
  );
}

export default CustomHandle;
