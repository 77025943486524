import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../types/TStore';
import type IAuthStore from './interfaces/IAuthStore';

const selectAuth = (state: RootState): IAuthStore => state.auth;

const selectJtw = createSelector(selectAuth, (auth) => auth.jwt);

export default selectJtw;
