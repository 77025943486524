import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import paramsSerializer from '../../utils/paramsSerializer';
import AUTHENTICATED_USER from '../tags/auth';

const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    paramsSerializer,
  }),
  tagTypes: [AUTHENTICATED_USER],
  endpoints: () => ({}),
});

export default baseApi;
