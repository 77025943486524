import {
  FormControl, FormField, FormItem, FormLabel,
} from '../../../../../components/ui/form';
import Label from '../../../../../components/ui/label';
import { RadioGroup, RadioGroupItem } from '../../../../../components/ui/radio-group';
import { SCORE } from '../../../../../constants/reactflow';
import cn from '../../../../../utils/helpers';

import type { FieldValues } from 'react-hook-form';
import type { IRadioBlock } from './interfaces/IRadioBlock';

function RadioBlock<T extends FieldValues>({ control, name, label }: IRadioBlock<T>) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormItem className="mb-4">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <RadioGroup orientation="horizontal" value={value?.toString()} className="flex">
              {SCORE.map((score) => {
                const isActive = score.value === value;

                return (
                  <div key={score.value} className="flex items-center mr-0.5">
                    <RadioGroupItem value={score.value.toString()} id={score.label} hidden />
                    <Label
                      onClick={() => onChange(isActive ? null : score.value)}
                      htmlFor={score.label}
                      className={cn(`flex justify-center items-center border-[1px] border-gray3
                              bg-card rounded-full w-[44px] h-[44px] font-normal text-[16px] cursor-pointer`, {
                        'border-none': isActive,
                        'text-card': isActive,
                        'bg-primary': isActive,
                        'hover:bg-primary': !isActive,
                        'hover:text-primary-foreground': !isActive,
                      })}
                    >
                      {score.label}
                    </Label>
                  </div>
                );
              })}
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );
}

export default RadioBlock;
