import './i18n';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import PageProtected from './layout/Page/PageProtected';
import Flow from './pages/Flow/Flow';
import Login from './pages/Login/Login';
import Projects from './pages/Projects/Projects';

import 'reactflow/dist/style.css';
import './styles/global.scss';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/login" element={<Login />} />
        <Route element={<PageProtected redirectUri="/login" />}>
          <Route index element={<Projects />} />
          <Route path="/flow/*" element={<Flow />} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default App;
