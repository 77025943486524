import * as yup from 'yup';

const numberValidator = yup.number().nullable().transform((value) => (Number.isNaN(value) ? null : value));

export const loginSchema = yup.object({
  identifier: yup.string().min(3).required('Email is required'),
  password: yup.string().min(3, 'Min 3').required('Password is required'),
}).required();

export const editProfileSchema = yup.object({
  firstName: yup.string().min(3).required(),
  lastName: yup.string().min(3).required(),
}).required();

export const businessUnitSchema = yup.object({
  name: yup.string().min(3).required('Name is required'),
  score: numberValidator.max(6),
  financialPerformance: numberValidator.max(2),
  stakeholders: yup.string().max(256, 'Max 256 characters'),
  description: yup.string().max(256, 'Max 256 characters'),
  sector: yup.string(),
  spendCurrency: yup.string(),
  incomeCurrency: yup.string(),
  financialPercent: numberValidator,
  spend: numberValidator,
  income: numberValidator,
}).required();

export const siteBlockSchema = yup.object({
  name: yup.string().min(3).required('Name is required'),
  country: yup.string(),
  city: yup.string(),
  sector: yup.string(),
  spend: numberValidator,
  spendCurrency: yup.string(),
  description: yup.string().max(256, 'Max 256 characters'),
}).required();

export const productSchema = yup.object({
  name: yup.string().min(3, 'Must be more than 3 characters').required('Name is required'),
  description: yup.string().max(256, 'Max 256 characters'),
  spendCurrency: yup.string(),
  revenueCurrency: yup.string(),
  profitCurrency: yup.string(),
  spend: numberValidator,
  revenue: numberValidator,
  profit: numberValidator,
}).required();

export const projectSchema = yup.object({
  id: yup.number().notRequired(),
  name: yup.string().min(3).required('Name is required'),
}).required();
