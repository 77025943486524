import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons';

import {
  FormControl, FormField, FormItem, FormLabel,
} from '../../../../../components/ui/form';
import { Input } from '../../../../../components/ui/input';
import Label from '../../../../../components/ui/label';
import { RadioGroup, RadioGroupItem } from '../../../../../components/ui/radio-group';

import type { IRadioGroupAndInput } from './interfaces/IRadioGroupAndInput';

function RadioGroupAndInput({
  control, radioName, radioLabel, inputName, placeholder,
}: IRadioGroupAndInput) {
  return (
    <div className="flex justify-between items-end">
      <FormField
        control={control}
        name={radioName}
        render={({ field: { onChange, value } }) => (
          <FormItem className="mb-4">
            <FormLabel>{radioLabel}</FormLabel>
            <FormControl>
              <RadioGroup orientation="horizontal" value={value?.toString()} className="flex">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    id="up"
                    value="2"
                    className="w-6 h-6"
                    onClick={() => onChange(value === 2 ? null : 2)}
                  />
                  <Label htmlFor="up">
                    {' '}
                    <ArrowUpIcon color="#30B90E" width={24} height={24} />
                  </Label>
                </div>
                <div className="w-11" />
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    id="down"
                    value="1"
                    className="w-6 h-6"
                    onClick={() => onChange(value === 1 ? null : 1)}
                  />
                  <Label htmlFor="down"><ArrowDownIcon color="#DC2626" width={24} height={24} /></Label>
                </div>
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name={inputName}
        render={({ field: { onChange, value } }) => (
          <FormItem className="mb-2">
            <FormControl>
              <Input
                type="number"
                placeholder={placeholder}
                onChange={onChange}
                value={value}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
}

export default RadioGroupAndInput;
