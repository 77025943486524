import { useTranslation } from 'react-i18next';
import { Pencil, Trash } from 'lucide-react';

import {
  Button,
} from '../../../components/ui/button';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,
} from '../../../components/ui/dropdown-menu';

import type { ReactElement } from 'react';
import type IDropDownMenu from '../interfaces/IDropDownMenu';

function DropDownMenu({ onRenameClick, onDeleteClick }: IDropDownMenu): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="absolute top-4 right-5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="flex flex-col gap-0.5 w-8 h-8 data-[state=open]:bg-primary [&>div]:data-[state=open]:bg-card"
          >
            <div className="w-[3px] bg-[#0D1314] h-[3px] rounded-full" />
            <div className="w-[3px] bg-[#0D1314] h-[3px] rounded-full" />
            <div className="w-[3px] bg-[#0D1314] h-[3px] rounded-full" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="">
          <DropdownMenuItem
            onClick={onRenameClick}
            className="flex gap-1 focus:bg-lightGreen focus:text-primary [&>svg]:focus:text-primary"
          >
            <Pencil width={16} height={16} className="text-icon" />
            {t('common.rename')}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={onDeleteClick}
            className="flex gap-1 focus:bg-lightGreen focus:text-primary [&>svg]:focus:text-primary"
          >
            <Trash width={16} height={16} className="text-icon" />
            {t('common.delete')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default DropDownMenu;
