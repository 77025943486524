import { useTranslation } from 'react-i18next';

import { Button } from '../../components/ui/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import cn from '../../utils/helpers';
import LeftSide from './components/LeftSide';

import type { ReactElement } from 'react';
import type ILoginView from './interfaces/ILoginView';

import classes from './sass/LoginView.module.scss';

/**
 * Login page view
 */
function LoginView({
  onLoginSubmit,
  isDisabled,
  control,
}: ILoginView): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="flex w-full h-screen">
      <LeftSide />
      <div className="w-6/12 flex justify-center items-center bg-gray z-20">
        <form onSubmit={onLoginSubmit} className={cn('flex flex-col flex-1', classes.form)}>
          <h2 className="text-center mb-6">{t('login.login')}</h2>
          <FormField
            control={control}
            name="identifier"
            render={({ field: { onChange, value } }) => (
              <FormItem className="mb-4">
                <FormLabel>{t('login.email')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('login.emailPlaceholder')}
                    onChange={onChange}
                    value={value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <FormItem>
                <FormLabel>{t('login.password')}</FormLabel>
                <FormControl>
                  <Input
                    onChange={onChange}
                    value={value}
                    placeholder={t('login.passwordPlaceholder')}
                    type="password"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="mt-4" type="submit" disabled={isDisabled}>{t('login.login')}</Button>
        </form>
      </div>
    </div>
  );
}

export default LoginView;
