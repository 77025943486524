import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@radix-ui/react-label';

import ButtonsBlock from '../../../../components/ButtonsBlock/ButtonsBlock';
import {
  DialogContent, DialogHeader, DialogTitle,
} from '../../../../components/ui/dialog';
import { Input } from '../../../../components/ui/input';
import CheckboxBlock from './components/CheckboxBlock';

import type { ChangeEventHandler } from 'react';
import type { ITransportMethod } from '../../../../constants/reactflow';
import type { IEdgeModal } from './interfaces/IEdgeModal';

function EdgeModal({ onCloseModal, onSave, editableEdge }: IEdgeModal) {
  const { t } = useTranslation();

  const [methods, setMethods] = useState<Array<ITransportMethod>>(editableEdge?.data?.methods || []);
  const [distance, setDistance] = useState<string>(editableEdge?.data?.distance || '');

  const addMethods = (method: ITransportMethod) => {
    if (methods.some((item) => item.value === method.value)) {
      setMethods(methods.filter((item) => item.value !== method.value));
    } else {
      setMethods([...methods, method]);
    }
  };

  const onSetDistance: ChangeEventHandler<HTMLInputElement> = (event) => {
    setDistance(event.target.value);
  };

  const onSaveHandler = () => {
    onSave({ methods, distance }, editableEdge?.id);
    setMethods([]);
    setDistance('');
  };

  return (
    <DialogContent className="w-[454px]">
      <DialogHeader>
        <DialogTitle>{t('projects.transport')}</DialogTitle>
      </DialogHeader>

      <CheckboxBlock methods={methods} addMethods={addMethods} label={t('projects.transportMethod')} />

      <Label className="text-sm font-medium">{t('projects.distance')}</Label>
      <Input placeholder={t('projects.distance')} onChange={onSetDistance} value={distance} type="number" />

      <ButtonsBlock onCancel={onCloseModal} onSubmit={onSaveHandler} />

    </DialogContent>
  );
}

export default EdgeModal;
