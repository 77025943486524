import endpoints from '../../../../constants/Endpoints';
import authApi from '../../../apis/authApi';
import PROJECTS from '../../../tags/project';

import type IBaseQueryPaginationResult from '../../../types/IBaseQueryPaginationResult';
import type IBaseQueryResult from '../../../types/IBaseQueryResult';
import type IProject from '../interfaces/IProject';
import type { IPutProjectRequest } from '../interfaces/IPutProjectRequest';

const authProjectApi = authApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<IBaseQueryPaginationResult<IProject[]>, void>({
      query: () => ({
        url: endpoints.projects(),
        method: 'GET',
      }),
      providesTags: [PROJECTS],
    }),
    getProjectById: builder.query<IBaseQueryResult<IProject>, string>({
      query: (id) => ({
        url: endpoints.projects(id),
        method: 'GET',
      }),
      providesTags: [PROJECTS],
    }),
    putProject: builder.mutation<IBaseQueryResult<IProject>, IPutProjectRequest>({
      query: (body) => ({
        url: endpoints.projects(body.id),
        method: 'PUT',
        body: body.data,
      }),
      invalidatesTags: [PROJECTS],
    }),
    deleteProject: builder.mutation<IBaseQueryResult<IProject>, { id: string }>({
      query: ({ id }) => ({
        url: endpoints.projects(id),
        method: 'DELETE',
      }),
      invalidatesTags: [PROJECTS],
    }),
    createProject: builder.mutation<IBaseQueryResult<IProject>, { name: string }>({
      query: (data) => ({
        url: endpoints.projects(),
        method: 'POST',
        body: {
          data: {
            name: data.name,
            data: {},
          },
        },
      }),
      invalidatesTags: [PROJECTS],
    }),
  }),
});

export const {
  usePutProjectMutation,
  useGetProjectByIdQuery,
  useGetProjectsQuery,
  useDeleteProjectMutation,
  useCreateProjectMutation,
} = authProjectApi;

export default {
  ...authProjectApi,
};
