import { useTranslation } from 'react-i18next';
import {
  PlusIcon,
} from '@radix-ui/react-icons';

import ProjectFormModal from './components/ProjectFormModal';
import ProjectItem from './components/ProjectItem';

import type { ReactElement } from 'react';
import type IProjectsView from './interfaces/IProjectsView';

function ProjectsView({
  projects,
  onCreateProject,
  isCreateFormModalOpen,
  isCreateLoading,
  setIsCreateFormModalOpen,
}: IProjectsView): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mx-6">
      <h1>{t('projects.valueChainProjects')}</h1>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid- gap-6 mt-4">
        <div
          className="rounded-md shadow-lg overflow-hidden bg-primary cursor-pointer"
          role="presentation"
          onClick={() => setIsCreateFormModalOpen(true)}
        >
          <div className="flex justify-center items-center flex-col h-full gap-4">
            <div className="text-primary bg-secondary flex w-fit rounded-full p-2">
              <PlusIcon width={24} height={24} />
            </div>
            <span className="text-base text-card font-bold">{t('common.createNew')}</span>
          </div>
        </div>
        {projects?.map((project) => (
          <ProjectItem key={project.id} project={project} />
        ))}
        {isCreateFormModalOpen ? (
          <ProjectFormModal
            isOpen={isCreateFormModalOpen}
            setIsOpen={setIsCreateFormModalOpen}
            onSubmit={onCreateProject}
            isLoading={isCreateLoading}
          />
        ) : null}
      </div>
    </div>
  );
}

export default ProjectsView;
