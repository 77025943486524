import React from 'react';

import logo from '../../../assets/images/logo.svg';
import logoSmall from '../../../assets/images/logoSmall.svg';

import classes from '../sass/LoginView.module.scss';

function LeftSide() {
  return (
    <div className="w-6/12 pt-8 pl-6 bg-secondary">
      <img
        src={logo}
        alt="logo"
        className={classes.logo}
      />
      <img
        src={logoSmall}
        alt="logoSmall"
        className={classes.logoSmall}
      />
    </div>
  );
}

export default LeftSide;
