import { useCallback, useState } from 'react';

import type { ReactElement } from 'react';
import type { ISubgroupNode } from './interface/ISubgroupNode';

function SubgroupNode({ id, data }: ISubgroupNode): ReactElement {
  const [inputValue, setInputValue] = useState(data.label || '');

  const onChange = useCallback((value: string) => {
    setInputValue(value);
  }, []);

  return (
    <div className="flex justify-center pt-[9px]">
      <textarea
        id={id}
        name="text"
        value={inputValue}
        onChange={({ target }) => onChange(target.value)}
        className="block w-[120px] font-semibold text-center border-none overflow-wrap
          break-word whitespace-pre-wrap resize-none overflow-hidden"
        maxLength={25}
        minLength={2}
        rows={2}
      />
    </div>
  );
}

export default SubgroupNode;
