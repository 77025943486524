export default {
  newProject: 'New Project',
  valueChainProjects: 'Value Chain Projects',
  valueChainProject: 'Value Chain Project',
  areYouSureYouWantToDelete: 'Are you sure you want to delete',
  delete: 'Delete',
  thisActionCannotBeUndone: 'This action cannot be undone',
  unitBlock: 'Bussiness unit block',
  sector: 'Sector',
  score: 'Score',
  financial: 'financial',
  financialPerformance: 'Financial Performance',
  spend: 'Spend',
  income: 'Income',
  siteBlock: 'Site Block',
  location: 'location',
  product: 'Product',
  revenue: 'Revenue',
  profit: 'Profit',
  description: 'Description',
  bussinessUnit: 'Bussiness Unit',
  name: 'Name',
  supplierCompany: 'Supplier Company',
  enterSector: 'Enter Sector',
  majorStakeholders: 'Major Stakeholders',
  cancel: 'Cancel',
  save: 'Save',
  create: 'Create',
  enterSumm: 'Enter sum',
  enterStakeholders: 'Enter stakeholders',
  typeYourMsg: 'Type your message here',
  myFactory: 'My factory',
  country: 'Country',
  city: 'City',
  myProduct: 'My product',
  namePlaceholder: 'Ex. “My Value Chain”',
  transport: 'Transport',
  transportMethod: 'Transport method',
  distance: 'Distance',
  km: 'km',
  projectSaved: 'Project successfully saved',
  projectSavedDescription: 'Your project has been saved successfully',
  whouldYouLikeToSaveProject: 'Would you like to save the project?',
  otherwiseYouWillLoseChanges: 'Otherwise, you will lose the latest changes',
  closeWithoutSaving: 'Close without saving',
  bussinessUnitName: 'Bussiness unit name',
  siteBlockName: 'Site block name',
  productName: 'Product name',
};
