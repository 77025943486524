import { Outlet, useLocation } from 'react-router-dom';

import { useAppDispatch } from '../../store/hooks/useApp';
import { logout } from '../../store/slices/auth/slice';
import cn from '../../utils/helpers';
import Sidebar from './components/Sidebar';

import type { ReactElement } from 'react';

/**
 * Main page layout
 */
function Page(): ReactElement {
  const dispatch = useAppDispatch();
  const onLogout = () => {
    dispatch(logout());
  };

  const { pathname } = useLocation();

  return (
    <div className="h-screen bg-gray flex">
      <Sidebar onLogout={onLogout} />
      <div className={cn('w-full', {
        'pt-8': !pathname.includes('/flow'),
      })}
      >
        <Outlet />
      </div>
    </div>
  );
}

export default Page;
