import { useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  Form,
} from '../../components/ui/form';
import { useAppSelector } from '../../store/hooks/useApp';
import { useLoginMutation } from '../../store/slices/auth/apis/authApi';
import selectJtwSelector from '../../store/slices/auth/selectors';
import { loginSchema } from '../../utils/validators';
import LoginView from './LoginView';

import type { ReactElement } from 'react';
import type ILoginForm from './interfaces/ILoginForm';

function Login(): ReactElement {
  const navigate = useNavigate();
  const jwt = useAppSelector(selectJtwSelector);
  const [login, { isLoading }] = useLoginMutation();
  const form = useForm<ILoginForm>({
    resolver: yupResolver(loginSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitted },
  } = form;

  const onLoginSubmit = handleSubmit(async (credentials): Promise<void> => {
    login(credentials)
      .unwrap()
      .catch(() => {
        reset();
        navigate('/');
      });
  });
  return jwt ? (
    <Navigate to="/" />
  ) : (
    <Form {...form}>
      <LoginView
        onLoginSubmit={onLoginSubmit}
        control={control}
        isDisabled={(!isValid && isSubmitted) || isLoading}
      />
    </Form>
  );
}

export default Login;
