import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import ButtonsBlock from '../../../../components/ButtonsBlock/ButtonsBlock';
import {
  DialogContent, DialogHeader, DialogTitle,
} from '../../../../components/ui/dialog';
import { Form } from '../../../../components/ui/form';
import { productSchema } from '../../../../utils/validators';
import InputAndSelectBlock from './components/InputAndSelectBlock';
import InputWithLabel from './components/InputWithLabel';
import TextAreaWithLabel from './components/TextAreaWithLabel';

import type { IProductModal, IProductNode } from './interfaces/IProductModal';

function ProductModal({ editableNode, createNode, onCloseModal }: IProductModal) {
  const { t } = useTranslation();

  const form = useForm<IProductNode>({
    resolver: yupResolver(productSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitted },
  } = form;

  const onSubmitHandler = handleSubmit((data: IProductNode) => {
    createNode(data, editableNode?.id);
    reset();
  });

  const onCloseModalHandler = () => {
    onCloseModal();
    reset();
  };

  useEffect(() => {
    reset({
      name: editableNode?.data.newNodeData?.name || '',
      spend: editableNode?.data.newNodeData?.spend,
      spendCurrency: editableNode?.data.newNodeData?.spendCurrency || '',
      revenue: editableNode?.data.newNodeData?.revenue,
      revenueCurrency: editableNode?.data.newNodeData?.revenueCurrency || '',
      profit: editableNode?.data.newNodeData?.profit,
      profitCurrency: editableNode?.data.newNodeData?.profitCurrency || '',
      description: editableNode?.data.newNodeData?.description || '',
    });
  }, [reset, editableNode]);

  return (
    <Form {...form}>
      <DialogContent className="w-[454px]">
        <DialogHeader>
          <DialogTitle>{t('projects.product')}</DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmitHandler}>
          <InputWithLabel
            control={control}
            name="name"
            label={`${t('projects.name')}*`}
            placeholder={t('projects.productName')}
          />

          <InputAndSelectBlock control={control} inputName="spend" selectName="spendCurrency" />
          <InputAndSelectBlock control={control} inputName="revenue" selectName="revenueCurrency" />
          <InputAndSelectBlock control={control} inputName="profit" selectName="profitCurrency" />

          <TextAreaWithLabel
            control={control}
            name="description"
            label={t('projects.description')}
            placeholder={t('projects.typeYourMsg')}
          />

          <ButtonsBlock
            onCancel={onCloseModalHandler}
            disabled={!isValid && isSubmitted}
          />

        </form>
      </DialogContent>
    </Form>
  );
}

export default ProductModal;
