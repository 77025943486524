import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  BackgroundVariant,
  ReactFlowProvider,
} from 'reactflow';

import { Dialog } from '../../components/ui/dialog';
import { useToast } from '../../components/ui/use-toast';
import {
  EDGE_TYPES, initialNodes, NODE_TYPES, REACTFLOW_SIZES, rfStyle, snapGrid,
} from '../../constants/reactflow';
import useSubgroupSelect from '../../hooks/useSubgroupSelect';
import { usePutProjectMutation } from '../../store/slices/project/apis/authProjectApi';
import { onBlockDrop, onSwimlaneDrop, processByDragType } from '../../utils/dnd';
import {
  calculateUnitNodeHeight,
  getCountOfNodeChildren, updateEdgeStyles, updateSubgroupPosition,
} from '../../utils/reactflow';
import CustomEdge from './components/CustomEdge/CustomEdge';
import EdgeTooltip from './components/EdgeTooltip/EdgeTooltip';
import Header from './components/Header/Header';
import BusinessUnitModal from './components/Modals/BusinessUnitModal';
import EdgeModal from './components/Modals/EdgeModal';
import ProductModal from './components/Modals/ProductModal';
import SaveModal from './components/Modals/SaveProject';
import SiteBlockModal from './components/Modals/SiteBlockModal';
import Product from './components/Product/Product';
import Sidebar from './components/Sidebar/Sidebar';
import SiteBlock from './components/SiteBlock/SiteBlock';
import SubgroupNode from './components/SubgroupNode/SubgroupNode';
import Unit from './components/Unit/Unit';

import type { DragEvent } from 'react';
import type {
  Edge, EdgeMouseHandler, Node, NodeMouseHandler, OnConnect, OnEdgesChange, OnNodesChange,
} from 'reactflow';
import type { TNodeTypes } from '../../constants/reactflow';
import type { IBusinessUnitNode } from './components/Modals/interfaces/IBusinessUnitModal';
import type { IEdgeInfo } from './components/Modals/interfaces/IEdgeModal';
import type { IProductNode } from './components/Modals/interfaces/IProductModal';
import type { ISiteBlockNode } from './components/Modals/interfaces/ISiteBlockModal';
import type { IFlowView } from './interfaces/IFlowView';

/*
  Add custom node and edge types (Custom components)
*/
const nodeTypes = {
  [NODE_TYPES.SUBGROUP]: SubgroupNode,
  [NODE_TYPES.UNIT]: Unit,
  [NODE_TYPES.BLOCK]: SiteBlock,
  [NODE_TYPES.PRODUCT]: Product,
};

const edgeTypes = {
  [EDGE_TYPES.CUSTOM]: CustomEdge,
};

function FlowView({
  id, name, projectNodes, projectEdges,
}: IFlowView) {
  const { t } = useTranslation();
  const { toast } = useToast();

  const [isUnsaved, setIsUnsaved] = useState(false);

  const [nodes, setNodes] = useState<Node[]>(projectNodes || initialNodes);
  const [edges, setEdges] = useState<Edge[]>(projectEdges || []);

  const [target, setTarget] = useState<Node | null | undefined>(null);
  const [draggedNodeType, setDraggedNodeType] = useState<TNodeTypes>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edgeModal, setEdgeModal] = useState(false);

  const [newConnection, setNewConnection] = useState<Edge | null>(null);
  const [edgeTooltip, setEdgeTooltip] = useState<any>(null);
  const [editableNode, setEditableNode] = useState<Node | undefined>(undefined);
  const [editableEdge, setEditableEdge] = useState<Edge | undefined>(undefined);

  const [putProject, { isLoading }] = usePutProjectMutation();

  const {
    NODE_HEIGHT,
    BLOCK_HEIGHT,
    NODE_WIDTH,
    GROUP_WIDTH,
    GROUP_PADDING,
  } = REACTFLOW_SIZES;

  const {
    UNIT,
    BLOCK,
    GROUP,
    PRODUCT,
    SUBGROUP,
    SWIMLANE,
  } = NODE_TYPES;

  // Block navigation if there are unsaved changes
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isUnsaved
      && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (projectNodes && projectNodes.length > 0) {
      setNodes(projectNodes);
    }
    if (projectEdges && projectEdges.length === 0) {
      setEdges(projectEdges);
    }
  }, [id, projectNodes, projectEdges]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes],
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      setEdgeTooltip(null);
      return setEdges((eds) => applyEdgeChanges(changes, eds));
    },
    [setEdges],
  );

  const onCloseModal = () => {
    setEditableNode(undefined);
    setIsModalOpen(false);
    setEdgeModal(false);
  };

  /* Add edge handler for new connection between nodes
  *  @param edgeData - IEdgeInfo -> { methods: Array<ITransportMethod>, distance: string }
  *  @param edgeId - string
  *  @returns void
  *  update edge if edgeId is provided/add edge if edgeId is not provided
  */
  const onAddEdgeHandler = (edgeData: IEdgeInfo, edgeId?: string) => {
    onCloseModal();

    if (edgeId) {
      const updatedEdges = edges.map((edge) => {
        if (edge.id === edgeId) {
          return { ...edge, data: { ...edgeData } };
        }

        return edge;
      });

      setEditableEdge(undefined);
      setEdges(updatedEdges);
    } else {
      if (!newConnection) return;

      setEdges((eds) => addEdge({
        ...newConnection, type: EDGE_TYPES.CUSTOM, zIndex: 100, data: { ...edgeData },
      }, eds));
    }

    setIsUnsaved(true);
  };

  const onConnectStart = (connection: Edge) => {
    setNewConnection(connection);
    setEdgeModal(true);
  };

  // Custom hook for subgroup selection and handle click outside
  useSubgroupSelect(setNodes);

  const onNodeDelete = (nodesToDelete: Node[]) => {
    const deletedNode = nodesToDelete[0];
    const nodeParent = deletedNode.parentNode!;

    const updatedNodesData = nodes.filter((n) => n.id !== deletedNode.id);

    const data = updateSubgroupPosition(updatedNodesData, nodeParent!);

    if (nodeParent.includes(UNIT)) {
      // const unitChildren = nodes.filter((n) => n.parentNode === nodeParent);

      const updatedNodes = data.map((n) => {
        if (n.id === nodeParent) {
          const divsCount = document.getElementById(n.id)?.childNodes.length || 0;
          // const height = unitChildren.length === 0 ? BLOCK_HEIGHT : n.height! - BLOCK_HEIGHT;
          const height = calculateUnitNodeHeight(data, n, divsCount) - BLOCK_HEIGHT;
          return {
            ...n,
            height,
            data: { ...n.data, height },
            style: { ...n.style, height },
          };
        }

        return n;
      });

      setNodes(updatedNodes);
    } else if (nodeParent.includes(GROUP) && !nodeParent.includes(SUBGROUP)) {
      // descrease Group node width after deleting a node
      const updatedNodes = data.map((n) => {
        if (n.id === nodeParent) {
          // get children count of the group node
          const childrenCount = getCountOfNodeChildren(data, nodeParent);

          return {
            ...n, style: { ...n.style, width: childrenCount * (NODE_WIDTH + GROUP_PADDING) + GROUP_PADDING },
          };
        }

        return n;
      });

      setNodes(updatedNodes);
    } else if (nodeParent.includes(SUBGROUP)
        && (deletedNode.type === BLOCK
        || deletedNode.type === PRODUCT
        || deletedNode.type === UNIT)
    ) {
      const subgroup = nodes.find((n) => n.id === nodeParent);
      const group = nodes.find((n) => n.id === subgroup?.parentNode);
      const allSubgroupsFromGroupIds = nodes
        .filter((n) => n.parentNode === subgroup?.parentNode)
        .map((n) => n.id);

      const allSubgroupsChildren = nodes.filter((n) => allSubgroupsFromGroupIds.includes(n.parentNode!));
      const lowestNode = allSubgroupsChildren.reduce((acc, el) => (el.position.y > acc.position.y ? el : acc), {
        position: { y: 0 },
      });

      const height = (lowestNode as Node)?.height || NODE_HEIGHT;

      if (group?.height && group.height > 600 && lowestNode.position.y + height < group.height) {
        const updatedNodes = data.map((n) => {
          if (n.id === group.id) {
            return {
              ...n,
              height: lowestNode.position.y + height + GROUP_PADDING,
              style: { ...n.style, height: lowestNode.position.y + height + GROUP_PADDING },
            };
          }

          if (n.parentNode === group?.id) {
            return {
              ...n,
              height: (lowestNode.position.y + height) - GROUP_PADDING * 2,
              style: { ...n.style, height: (lowestNode.position.y + height) - GROUP_PADDING * 4 },
            };
          }

          return n;
        });

        setNodes(updatedNodes);
      }
    } else {
      setNodes(data);
    }

    setIsUnsaved(true);
  };

  /* Drop handler for nodes (business unit, site block, product, swimlane)
  *  @param event - DragEvent
  *  @returns void
  *  open modal for node creation
  */
  const onDropHandler = (event: DragEvent) => {
    const type = event?.dataTransfer?.getData('application/reactflow') || draggedNodeType;

    if (type === SWIMLANE) {
      if (!target || !(target.type === SUBGROUP || target.type === GROUP)) return;

      onSwimlaneDrop(nodes, setNodes, setTarget, target);

      return;
    }

    setDraggedNodeType(type);
    setIsModalOpen(true);
    setIsUnsaved(true);
  };

  // Update node in modal
  const updateNode = (updatedData: IBusinessUnitNode | ISiteBlockNode | IProductNode, updateId: string) => {
    const isUnitNode = updateId.includes(UNIT);
    let divCountDiff = 0;

    const updatedNodes = nodes.map((node) => {
      if (node.id === updateId) {
        // check if its a Unit node 'cause we need to update children Y position
        if (node.type === UNIT) {
          // check if Unit node has new div elements (newNodeData)
          divCountDiff = Object.keys(updatedData).length - Object.keys(node.data.newNodeData).length;
          const countOfNodeChildren = getCountOfNodeChildren(nodes, updateId);

          // calculate new height for the Unit node according to differece in div elements
          const updatedHeight = countOfNodeChildren > 0
            ? (node.height || NODE_HEIGHT) + (divCountDiff / 2) * 10
            : node.height || NODE_HEIGHT;

          return {
            ...node,
            data: {
              ...node.data,
              height: updatedHeight,
              newNodeData: updatedData,
            },
            height: updatedHeight,
            style: { ...node.style, height: updatedHeight },
          };
        }

        return { ...node, data: { ...node.data, newNodeData: updatedData } };
      }

      return node;
    });

    // update UNIT node children Y position after updating the node
    if (isUnitNode && divCountDiff !== 0) {
      const updatedPositions = updatedNodes.map((n) => {
        if (n.parentNode === updateId) {
          return {
            ...n,
            position: {
              x: n.position.x,
              y: n.position.y + ((divCountDiff / 2) * 10),
            },
          };
        }

        return n;
      });

      setNodes(updatedPositions);
    } else {
      setNodes(updatedNodes);
    }

    setEditableNode(undefined);
    onCloseModal();
  };

  /* Drop handler for nodes (business unit, site block, product, swimlane)
  *  @param data - IBusinessUnitNode | ISiteBlockNode | IProductNode | null
  *  @param updateId - string
  *  @returns void
  *
  *  update node if updateId is provided/add node if updateId is not provided
  */
  const modalSaveHandler = (data: IBusinessUnitNode | ISiteBlockNode | IProductNode | null, updateId?: string) => {
    if (!data) return;

    if (updateId) {
      updateNode(data, updateId);
    } else if (data) {
      onBlockDrop(nodes, setNodes, setTarget, target, draggedNodeType, data);
      onCloseModal();
    }

    setIsUnsaved(true);
  };

  const onSaveProject = () => {
    const bodyData = {
      data: {
        name,
        data: { nodes, edges },
      },
    };

    putProject({ id, data: bodyData }).unwrap().then(() => {
      setIsUnsaved(false);

      toast({
        title: t('projects.projectSaved'),
        description: t('projects.projectSavedDescription'),
      });

      if (blocker && blocker.proceed) {
        blocker.proceed();
      }
    }).catch(() => {
      toast({
        variant: 'destructive',
        title: t('common.wentWrong'),
        description: t('common.tryAgain'),
      });
    });
  };

  /* Double click handler for nodes (business unit, site block, product, swimlane)
  *  @param event - MouseEvent
  *  @param targetNode - Node
  *  @returns void
  * open modal for node editing
  */
  const onDoubleClick: NodeMouseHandler = (event, targetNode) => {
    setDraggedNodeType('');

    if (targetNode) {
      setEditableNode(targetNode);
      setIsModalOpen(true);
    }
  };

  /* Double click handler for edges
  *  @param event - MouseEvent
  *  @param edge - Edge
  *  @returns void
  *  open modal for edge editing
  */
  const onEdgeDoubleClick: EdgeMouseHandler = (event, edge) => {
    setEditableEdge(edge);
    setEdgeModal(true);
  };

  return (
    <Dialog open={isModalOpen || edgeModal} onOpenChange={onCloseModal}>
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={updateEdgeStyles(edges)}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeDragStop={() => setIsUnsaved(true)}
          onConnect={onConnectStart as OnConnect}
          onDrop={onDropHandler}
          onEdgeDoubleClick={onEdgeDoubleClick}
          onNodeDoubleClick={onDoubleClick}
          onInit={(rfInstance) => rfInstance.fitView()}
          onEdgeMouseEnter={(event, edge) => setEdgeTooltip({ event, edge })}
          onEdgeMouseLeave={() => setEdgeTooltip(null)}
          onDragOver={(event) => processByDragType(event, nodes, setNodes, setTarget, draggedNodeType)}
          onNodesDelete={onNodeDelete}
          defaultViewport={{
            x: GROUP_WIDTH + GROUP_PADDING * 2,
            y: 50,
            zoom: 1,
          }}
          preventScrolling
          snapToGrid
          snapGrid={snapGrid as [number, number]}
          minZoom={0.5}
          maxZoom={2}
          style={rfStyle}
        >
          <Background variant={BackgroundVariant.Dots} gap={7} />
          <Header projectName={name} onClick={onSaveProject} isLoading={isLoading} />
        </ReactFlow>
        <Sidebar setDraggedNodeType={setDraggedNodeType} />

        {edgeTooltip
          ? (
            <EdgeTooltip
              positionX={edgeTooltip.event.pageX}
              positionY={edgeTooltip.event.pageY}
              data={edgeTooltip.edge.data}
            />
          )
          : null}

        <SaveModal blocker={blocker} onSaveProject={onSaveProject} />

        {edgeModal
          ? <EdgeModal onCloseModal={onCloseModal} onSave={onAddEdgeHandler} editableEdge={editableEdge} />
          : null}
        {!edgeModal && (draggedNodeType === UNIT || editableNode?.type === UNIT)
          && (
            <BusinessUnitModal
              editableNode={editableNode}
              onCloseModal={onCloseModal}
              createNode={modalSaveHandler}
            />
          )}
        {!edgeModal && (draggedNodeType === BLOCK || editableNode?.type === BLOCK)
          && (
            <SiteBlockModal
              editableNode={editableNode}
              onCloseModal={onCloseModal}
              createNode={modalSaveHandler}
            />
          )}
        {!edgeModal && (draggedNodeType === PRODUCT || editableNode?.type === PRODUCT)
          && (
            <ProductModal
              editableNode={editableNode}
              onCloseModal={onCloseModal}
              createNode={modalSaveHandler}
            />
          )}
      </ReactFlowProvider>
    </Dialog>
  );
}

export default FlowView;
