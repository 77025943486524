import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateProjectMutation, useGetProjectsQuery } from '../../store/slices/project/apis/authProjectApi';
import ProjectsView from './ProjectsView';

import type { ReactElement } from 'react';
import type TProjectForm from './interfaces/TProjectForm';

function Projects(): ReactElement {
  const navigate = useNavigate();
  const [isCreateFormModalOpen, setIsCreateFormModalOpen] = useState<boolean>(false);

  const { data } = useGetProjectsQuery();
  const [createProject, {
    isLoading: isCreateLoading,
  }] = useCreateProjectMutation();

  const onCreateProject = (newProject: TProjectForm) => {
    createProject({ name: newProject.name }).unwrap().then((response) => {
      const { id } = response.data;
      setIsCreateFormModalOpen(false);
      navigate(`/flow/${id}`);
    });
  };

  return (
    <ProjectsView
      projects={data?.data}
      onCreateProject={onCreateProject}
      isCreateFormModalOpen={isCreateFormModalOpen}
      isCreateLoading={isCreateLoading}
      setIsCreateFormModalOpen={setIsCreateFormModalOpen}
    />
  );
}

export default Projects;
