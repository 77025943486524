export enum InfoBlockType {
  Score = 'score',
  FinancialUp = 'financialUp',
  FinancialDown = 'financialDown',
}

export interface IInfoBlock {
  id?: string,
  title: string,
  value?: string | null,
  type?: InfoBlockType,
  currency?: string,
}
