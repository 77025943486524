import endpoints from '../../../../constants/Endpoints';
import baseApi from '../../../apis/baseApi';

import type ILoginRequest from '../interfaces/ILoginRequest';
import type ILoginResponse from '../interfaces/ILoginResponse';

const authBaseApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (body) => ({
        url: endpoints.login(),
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
} = authBaseApi;

export default {
  ...authBaseApi,
};
