import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import ButtonsBlock from '../../../../components/ButtonsBlock/ButtonsBlock';
import {
  DialogContent, DialogHeader, DialogTitle,
} from '../../../../components/ui/dialog';
import { Form } from '../../../../components/ui/form';
import { businessUnitSchema } from '../../../../utils/validators';
import InputAndSelectBlock from './components/InputAndSelectBlock';
import InputWithLabel from './components/InputWithLabel';
import RadioBlock from './components/RadioBlock';
import RadioGroupAndInput from './components/RadioGroupAndInput';
import TextAreaWithLabel from './components/TextAreaWithLabel';

import type { IBusinessUnitModal, IBusinessUnitNode } from './interfaces/IBusinessUnitModal';

function BusinessUnitModal({ editableNode, createNode, onCloseModal }: IBusinessUnitModal) {
  const { t } = useTranslation();

  const form = useForm<IBusinessUnitNode>({
    resolver: yupResolver(businessUnitSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitted },
  } = form;

  const onSubmitHandler = handleSubmit((data: IBusinessUnitNode) => {
    createNode(data, editableNode?.id);
    reset();
  });

  const onCloseModalHandler = () => {
    onCloseModal();
    reset();
  };

  useEffect(() => {
    reset({
      name: editableNode?.data.newNodeData?.name || '',
      sector: editableNode?.data.newNodeData?.sector || '',
      score: editableNode?.data.newNodeData?.score || '',
      financialPerformance: editableNode?.data.newNodeData?.financialPerformance || '',
      financialPercent: editableNode?.data.newNodeData?.financialPercent || '',
      spend: editableNode?.data.newNodeData?.spend,
      spendCurrency: editableNode?.data.newNodeData?.spendCurrency || '',
      income: editableNode?.data.newNodeData?.income,
      incomeCurrency: editableNode?.data.newNodeData?.incomeCurrency || '',
      stakeholders: editableNode?.data.newNodeData?.stakeholders || '',
      description: editableNode?.data.newNodeData?.description || '',
    });
  }, [reset, editableNode]);

  return (
    <Form {...form}>
      <DialogContent
        className="w-[454px]"
      >
        <DialogHeader>
          <DialogTitle>{t('projects.bussinessUnit')}</DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmitHandler}>
          <div className="h-[70vh] overflow-y-scroll">
            <InputWithLabel
              control={control}
              name="name"
              label={`${t('projects.name')}*`}
              placeholder={t('projects.bussinessUnitName')}
            />
            <InputWithLabel
              control={control}
              name="sector"
              label={t('projects.sector')}
              placeholder={t('projects.sector')}
            />

            <RadioBlock control={control} name="score" label={t('projects.score')} />
            <RadioGroupAndInput
              control={control}
              radioName="financialPerformance"
              radioLabel={t('projects.financialPerformance')}
              inputName="financialPercent"
              placeholder="%"
            />

            <InputAndSelectBlock control={control} inputName="spend" selectName="spendCurrency" />
            <InputAndSelectBlock control={control} inputName="income" selectName="incomeCurrency" />

            <TextAreaWithLabel
              control={control}
              name="stakeholders"
              label={t('projects.majorStakeholders')}
              placeholder={t('projects.enterStakeholders')}
            />
            <TextAreaWithLabel
              control={control}
              name="description"
              label={t('projects.description')}
              placeholder={t('projects.typeYourMsg')}
            />
          </div>

          <ButtonsBlock
            onCancel={onCloseModalHandler}
            disabled={!isValid && isSubmitted}
          />

        </form>
      </DialogContent>
    </Form>
  );
}

export default BusinessUnitModal;
