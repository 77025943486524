import { useEffect, useState } from 'react';

import { NODE_TYPES, SwimlaneBorder } from '../constants/reactflow';
import { resetSubgroupSelectedStyles } from '../utils/reactflow';

import type { Node } from 'reactflow';
import type { IUseSubgroupSelect } from './interfaces/IUseSubgroupSelect';

function useSubgroupSelect(callback: IUseSubgroupSelect['callback']) {
  const [selectedId, setSelectedDId] = useState('');

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      const id = target?.dataset?.id;

      if (!id?.includes(NODE_TYPES.SUBGROUP)) {
        callback((nodes: Node[]) => resetSubgroupSelectedStyles(nodes));
      } else {
        setSelectedDId(target?.dataset?.id || '');

        callback((nodes: Node[]) => nodes.map((node) => {
          if (node.type === NODE_TYPES.SUBGROUP) {
            return {
              ...node,
              style: {
                ...node.style,
                border: node.id === id ? '1px solid #cecece' : SwimlaneBorder,
              },
            };
          }

          return node;
        }));
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback]);

  return { selectedId };
}

export default useSubgroupSelect;
