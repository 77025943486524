import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import ButtonsBlock from '../../../components/ButtonsBlock/ButtonsBlock';
import {
  Dialog, DialogContent,
  DialogHeader, DialogTitle,
} from '../../../components/ui/dialog';
import {
  Form, FormControl, FormField, FormItem, FormLabel, FormMessage,
} from '../../../components/ui/form';
import { Input } from '../../../components/ui/input';
import { projectSchema } from '../../../utils/validators';

import type { ReactElement } from 'react';
import type IProjectFormModal from '../interfaces/IProjectFormModal';
import type TProjectForm from '../interfaces/TProjectForm';

function ProjectFormModal({
  isOpen, setIsOpen, project, onSubmit, isLoading,
}: IProjectFormModal): ReactElement {
  const { t } = useTranslation();

  const form = useForm<TProjectForm>({
    defaultValues: {
      id: project?.id || undefined,
      name: project?.attributes.name || '',
    },
    resolver: yupResolver(projectSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitted, isDirty },
  } = form;

  const onSubmitHandler = handleSubmit((data: TProjectForm) => {
    onSubmit(data);
    reset();
  });

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const isDisabled = (!isValid && isSubmitted) || !isDirty || isLoading;

  return (
    <Dialog open={isOpen} onOpenChange={onCloseModal}>
      <Form {...form}>
        <DialogContent className="w-[454px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">{t('projects.valueChainProject')}</DialogTitle>
          </DialogHeader>

          <form onSubmit={onSubmitHandler} className="mt-2">
            <FormField
              control={control}
              name="name"
              render={({ field: { onChange, value } }) => (
                <FormItem>
                  <FormLabel>{t('common.name')}</FormLabel>
                  <FormControl>
                    <Input
                      onChange={onChange}
                      value={value}
                      placeholder={t('projects.namePlaceholder')}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ButtonsBlock
              onCancel={() => reset(onCloseModal)}
              disabled={isDisabled}
              submitTitle={project ? t('projects.save') : t('projects.create')}
            />
          </form>
        </DialogContent>
      </Form>
    </Dialog>
  );
}

export default ProjectFormModal;
