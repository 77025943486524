import { useTranslation } from 'react-i18next';

import ButtonsBlock from '../../../components/ButtonsBlock/ButtonsBlock';
import {
  Dialog, DialogContent,
  DialogHeader, DialogTitle,
} from '../../../components/ui/dialog';

import type { ReactElement } from 'react';
import type IProjectDeleteModal from '../interfaces/IProjectDeleteModal';

function ProjectForm({
  isOpen, setIsOpen, project, onDelete, isLoading,
}: IProjectDeleteModal): ReactElement {
  const { t } = useTranslation();

  const onCloseModal = () => {
    setIsOpen(false);
  };
  return (
    <Dialog open={isOpen} onOpenChange={onCloseModal}>
      <DialogContent className="w-[454px]">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            {`${t('projects.areYouSureYouWantToDelete')} “${project?.attributes.name}”?`}
          </DialogTitle>
        </DialogHeader>
        <span className="text-sm text-icon font-medium">{t('projects.thisActionCannotBeUndone')}</span>
        <ButtonsBlock
          onCancel={() => onCloseModal()}
          submitTitle={t('projects.delete')}
          submitVariant="destructive"
          onSubmit={onDelete}
          disabled={isLoading}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ProjectForm;
