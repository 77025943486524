import { useTranslation } from 'react-i18next';

import cn from '../../utils/helpers';
import { Button } from '../ui/button';

import type { IButtonBlock } from './interfaces/IButtonBlock';

function ButtonsBlock({
  onCancel, submitTitle, cancelTitle, onSubmit, submitClassName, submitVariant, disabled = false,
}: IButtonBlock) {
  const { t } = useTranslation();

  return (
    <div className="flex mt-4 ">
      <Button
        onClick={onCancel}
        type="reset"
        className="flex-1"
        variant="outline"
      >
        {cancelTitle ?? t('projects.cancel')}
      </Button>
      <div className="w-2" />
      <Button
        variant={submitVariant}
        className={cn('flex-1', submitClassName)}
        type="submit"
        disabled={disabled}
        onClick={onSubmit}
      >
        {submitTitle ?? t('projects.save')}
      </Button>
    </div>
  );
}

export default ButtonsBlock;
