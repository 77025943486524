import { Position } from 'reactflow';

import CustomHandle from './CustomHandle';

import type { ISourceAndTarget } from './interfaces/ISourceAndTarget';

function SourceAndTarget({ id }: ISourceAndTarget) {
  return (
    <>
      <CustomHandle type="target" position={Position.Left} id={id} />
      <CustomHandle type="source" position={Position.Right} id={id} />
    </>
  );
}

export default SourceAndTarget;
