import { MarkerType } from 'reactflow';

import {
  EDGE_TYPES,
  NODE_TYPES,
  REACTFLOW_SIZES,
  SCORE,
  SUBGROUP_Y,
  SwimlaneBorder,
} from '../constants/reactflow';

import type { Edge, Node } from 'reactflow';
import type { TScoreValue } from '../constants/reactflow';

const {
  NODE_WIDTH,
  BLOCK_HEIGHT,
  GROUP_WIDTH,
  GROUP_PADDING,
  SUBGROUP_V_PADDING,
  UNIT_BLOCK_V_PADDING,
} = REACTFLOW_SIZES;

export const calculateSubgroupX = (index: number, width: number = NODE_WIDTH): number => {
  const result = index * width + (GROUP_PADDING * (index + 1));
  return result;
};

export const getCountOfNodeChildren = (nodes: Node[], targetId: string): number => {
  const result = nodes.filter((n) => n.parentNode === targetId).length;

  return result;
};

export const calculateSubgroupWidth = (nodeChildCount: number): number => {
  const width = (GROUP_WIDTH - (GROUP_PADDING * (nodeChildCount + 2))) / (nodeChildCount + 1);

  return width;
};

export const resetOnDragOverStyles = (nodes: Node[]): Node[] => {
  const result = nodes.map((n) => {
    if (n.type === NODE_TYPES.SUBGROUP) return n;

    if (n.type === NODE_TYPES.SWIMLANE) {
      return { ...n, style: { ...n.style, opacity: 1, border: SwimlaneBorder }, position: { x: 1200, y: 0 } };
    }

    return { ...n, style: { ...n.style, opacity: 1, border: 'none' } };
  });

  return result;
};

export const resetSubgroupSelectedStyles = (nodes: Node[]): Node[] => {
  const result = nodes.map((n) => {
    if (n.type === NODE_TYPES.SUBGROUP) {
      return { ...n, style: { ...n.style, border: SwimlaneBorder } };
    }

    return n;
  });

  return result;
};

export const updateSubgroupPosition = (nodes: Node[], targetId: string): Node[] => {
  const result = nodes.map((n) => {
    if (n.parentNode === targetId && n.type === NODE_TYPES.SUBGROUP) {
      // get subguoups of the current node
      const currentNodeSubgroups = nodes
        .filter((node) => node.parentNode === targetId && node.type === NODE_TYPES.SUBGROUP);

      // get the index of the current node in the subgroups to calculate the x position
      const subgroupdIndex = currentNodeSubgroups.findIndex((node) => node.id === n.id);

      return {
        ...n, position: { x: calculateSubgroupX(subgroupdIndex), y: SUBGROUP_Y },
      };
    }

    return n;
  });

  return result;
};

export const getScoreValue = (score?: TScoreValue) => SCORE.find((s) => s.value === score)?.label;

export const calculateUnitNodeHeight = (nodes: Node[], targetNode: Node, divsCount: number): number => {
  const childrenCount = getCountOfNodeChildren(nodes, targetNode.id);

  return childrenCount > 0
    ? ((childrenCount + 2) * BLOCK_HEIGHT) + (SUBGROUP_V_PADDING * (childrenCount + 2)) + SUBGROUP_V_PADDING
    : BLOCK_HEIGHT + (divsCount * 12) + UNIT_BLOCK_V_PADDING * 2;
};

// Update all Edges (apply custom styles and styles for selected edges)
export const updateEdgeStyles = (edgesData: Edge[]): Edge[] => {
  const result = edgesData.map((e) => ({
    ...e,
    type: EDGE_TYPES.CUSTOM,
    style: { stroke: '#005D67' },
    animated: !e.selected, // animate edge if it's not selected
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#005D67',
      width: 24,
      height: 18,
    },
  }));

  return result;
};
